import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"

const PrivacyPolicyPageWrapper = styled(PageWrapper)`
  padding-top: 4rem;

  color: ${props => props.theme.color.primary};
`

export default function ShakeRunPrivacyPolicyPage() {
  return (
    <PrivacyPolicyPageWrapper>
      <Seo title="Privacy Policy" />
      <Row>
        <h1>Privacy Policy</h1>

        <h2>f'real foods, LLC</h2>
        <p>Effective Date: June 20, 2021</p>
        <p>
          This privacy policy ("Policy") describes f'real foods LLC ‘s
          ("f’real," "we," "us") practices and use of the information that
          f’real collects from users ("you") who use this f’real mobile
          application (the "App"). By using this App, you agree to the
          collection and use of your personal information as described in this
          Policy. f’real reserves the right to update this Policy and will post
          any changes on this page, so check back regularly. Your continued use
          of the App following any changes will constitute your acceptance to be
          bound by the Policy as modified.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We do not collect or receive sensitive or financial information
          through this App. When you download or use the App, however, f’real
          may receive personal information about you that this app store
          collects. Please review the app store’s privacy policy for more
          information about the types of data that it collects, how that data is
          used and shared, and how you may limit collection and sharing of such
          data. The app store privacy policy is available at:{" "}
          <a
            href="https://www.apple.com/legal/privacy/en-ww/"
            target="_blank"
            rel="noreferrer"
          >
            Apple Privacy Policy
          </a>{" "}
          /{" "}
          <a
            href="https://policies.google.com/privacy?hl=en-US"
            target="_blank"
            rel="noreferrer"
          >
            Google Privacy Policy
          </a>
          .
        </p>
        <p>
          The App provides you with the ability to save your game play
          information locally on your device. By doing so, you are exporting the
          information out of the App, and such information will be subject to
          your device’s privacy settings.
        </p>

        <p>
          The App also provides you with the ability to share your game play
          information on any social media platform or messaging app of your
          choosing. When you choose to share your game play information, you do
          so by exporting that information out of the App, and that information
          may become public information and will be subject to the privacy
          policies and practices of the relevant social media platform or
          messaging app. f’real does not collect information from or share any
          of your personal information with any social media platform or
          messaging app.
        </p>

        <p>
          Your Apple Game Center/Google Play user name may also appear on a
          "leader board" that shows the users who have achieved the highest
          scores in the App, along with those scores. This may be visible to
          users of the App or individuals who use Apple Game Center/Google Play.
          f’real does not collect any information regarding your username or
          scores. This leader board is populated and controlled by Apple/Google,
          and you may refer to its privacy policy for information regarding how
          your information is collected, used, and shared.
        </p>

        <p>
          Please note that the app store, social media platforms, and messaging
          apps are owned and operated by third-party companies that are not
          owned by or affiliated with f’real. f’real has no control over, does
          not review, and is not responsible for any third party’s website,
          content, or collection or use of personal information. This Policy
          applies only to this App and does not apply to any websites, including
          any f’real websites. We encourage you to read the privacy policies
          and/or cookie notices of every website you visit.
        </p>

        <p>
          f’real does not knowingly collect personal information from visitors
          to the Site who are under thirteen (13) years of age. f’real reserves
          the right to delete any information that we believe to be in violation
          of this Policy. If you believe that we might have any personal
          information from a child under 13, please let us know by contacting us
          using the information listed at the end of this Policy.
        </p>

        <h2>How We Use Information</h2>
        <p>
          The App is operated and is intended for use in the United States and
          Canada. Accordingly, any information you provide by using the App
          (including information that you provide to the app store) may be
          transferred to the United States and/or Canada and by using the App
          and providing your information, you consent to such transfer. You also
          understand that the United States and Canada may not provide the same
          level of protections as the laws of your country.
        </p>
        <p>
          We may also use non-identifying and aggregate information about the
          App and its users for internal purposes, including but not limited to
          improvement of the App, tracking the level of activity and engagement
          in the App, and address technical issues such as performance or crash
          diagnostics.
        </p>
        <p>
          We do not sell your personal information. We also do not disclose your
          personal information to any other third parties except as necessary to
          third-party contractors who help us operate our business and the App,
          or as necessary to comply with legal obligations, including but not
          limited to, a judicial proceeding, court order, or legal process
          served on f’real foods.
        </p>
        <p>
          If f'real is sold to or merges with another company, some or all of
          the information collected from you may be transferred to a third party
          as a result of the transfer of assets.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or suggestions regarding this Policy, you
          may contact us through the following:
        </p>
        <p>
          <a href="mailto:events@freal.com">events@freal.com</a>
        </p>
        <p>6121 Hollis St. Suite #500, Emeryville, CA 94608</p>
        <p>1-877-367-7325</p>
      </Row>
    </PrivacyPolicyPageWrapper>
  )
}
